<template>
  <div class="service">
    <div class="panel">
      <div class="text">Please contact us via following email address</div>
      <div class="text email">info@jfmobility.com</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.service {
  display: flex;
  width: 100%;
  height: 100%;
  background: url("../../assets/img/contact.png") center center;
  background-size: cover;
  .panel {
    width: 100%;
    height: 421.5px;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    background: #02020263;
    border: 0.75px solid #000000;
    padding: 50px 120px;
    font-size: 3.33vh;
    font-family: Helvetica, Helvetica-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 43.5px;
    letter-spacing:0.117vw;
    .text {
      margin: 60px 0px;
      font-family: Times New Roman, Times New Roman-Regular;
    }
    .email {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
